import React from 'react';
import { Image, Platform } from 'react-native';
import { Auth as Authorization } from 'aws-amplify';
import * as AppAction from '../../redux/actions/appAction';
import * as LoginAction from '../../redux/actions/loginAction';

import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../redux/reducers';
import { Box, HStack, IconButton, StatusBar, Text, Menu, Pressable, VStack } from 'native-base';
import { imageStyle } from 'src/styles/imageStyle';
import MaterialIconByName from 'src/components/MaterialIconByName';
import { getText } from 'src/utils/i18n';

export default function CustomNavigationBar({ navigation, options }) {

  const organization = useSelector((store: IStore) => store.user.userProfile.response?.org);
  const centerName = useSelector((store: IStore) => store.user.userProfile.response?.center.name);
  const dongleId = useSelector((store: IStore) => store.user.userProfile.response?.dongle?.dongleId);
  
  const [visible, setVisible] = React.useState(false);
  const [switchVisible, setSwitchVisible] = React.useState(false);
  const closeMenu = () => setVisible(false);
  const locale = useSelector((state: RootState) => state.app.locale);
  const dispatch = useDispatch();

  return (
    <>
      <>
        <StatusBar barStyle="light-content" />
        <Box safeAreaTop />
        <HStack bg="gray.900" px="1" py="1" justifyContent="space-between" alignItems="center" w="100%">
          {
            options.previous && <IconButton
              hitSlop={10}
              onPress={navigation.goBack}
              icon={<MaterialIconByName name="keyboard-backspace" color='white' />} />
          }
          <HStack style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }} alignItems="center">
            {
              options.showTitle
              && <Text
                style={{
                  marginLeft: options.previous ? -24 : 0
                }}
                color={"white"}
                fontSize="20" fontWeight="bold">
                {options.title === undefined ? "GRAMS" : options.title}
              </Text>
            }
            {
              options.dongleId
              && <>
                <VStack style={{
                  marginLeft: options.previous ? -24 : 0,
                  alignItems: "center",
                }}>
                  <Text
                    color={"white"}
                    fontSize="20" fontWeight="bold">
                    {centerName === undefined ? (options.title === undefined ? "GRAMS" : options.title) : `${centerName}`}
                  </Text>
                  {dongleId !== undefined
                    && dongleId !== null
                    && dongleId !== ""
                    &&
                    < Text
                      color={"white"}
                      fontSize="16">
                      {`Dongle ID: ${dongleId}`}
                    </Text>}
                </VStack>
              </>
            }
            {
              (options.logo) && <Image
                style={[imageStyle.logoStyle, {
                  marginLeft: options.previous ? -48 : 0
                }]}
                source={Platform.OS === 'web' ? { uri: "https://grams-images-bucket.s3.us-west-2.amazonaws.com/grams/grams_logo.jpg" }
                  : require('./login_page_logo.png')} />
            }
            {
              options.orgLogo && organization?.logo_key && <Image
                style={[imageStyle.logoStyle, {
                  marginLeft: options.previous ? -48 : 0
                }]}
                source={{ uri: `https://grams-images-bucket.s3.us-west-2.amazonaws.com/${organization?.logo_key}` }} />
            }
          </HStack>
          <HStack>
            {
              options.settings &&
              <Menu w="190" trigger={triggerProps => {
                return <Pressable
                  style={{
                    padding: 12
                  }}
                  hitSlop={10}
                  accessibilityLabel="More options menu" {...triggerProps}>
                  <MaterialIconByName name="dots-vertical" size={"xl"} color='white' />
                </Pressable>;
              }}>
                {
                  options.showGrainType &&
                  <>
                    <Menu.Item onPress={() => navigation.navigate('ChooseGrainTypePage')}>{getText('chooseGrainType')}</Menu.Item>

                  </>
                }
                <Menu.Item onPress={() => navigation.navigate('UserGuidePage')}>{getText('userGuide')}</Menu.Item>
                <Menu.Item onPress={() => {
                  navigation.navigate('SettingsPage')
                }}>{getText('settings')}</Menu.Item>
                {switchVisible && <Menu.Item onPress={() => {
                  navigation.navigate('ChooseLoginTypePage')
                }}>Change Role</Menu.Item>}
                <Menu.Item onPress={async () => {
                  dispatch(AppAction.logout());
                  closeMenu()
                  await Authorization.signOut()
                  dispatch(LoginAction.setUserLoggedIn("NOT_LOGGED_IN"))
                }}>{getText('signOut')}</Menu.Item>
              </Menu>
            }
          </HStack>
        </HStack>
      </>
    </>
  );
}