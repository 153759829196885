
import { Platform } from "react-native";
import { getPlatform } from "./miscUtils";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { S3_BUCKET_URL } from 'src/constants';
function getAvatarInitials(textString) {
    if (!textString) return "";
    const text = textString.trim();
    const textSplit = text.split(" ");
    if (textSplit.length <= 1) return text.toUpperCase().charAt(0);
    const initials =
        textSplit[0].toUpperCase().charAt(0) + textSplit[textSplit.length - 1].toUpperCase().charAt(0);
    return initials;
};

function uniqByKeepFirst(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function getUniqueJobs(a, key) {
    a = a.sort(function (a, b) { return b['updatedAt'] - a['updatedAt'] });
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

function getUniqueItems(arr, key) {
    return [...new Set(arr?.map(item => item[key]))]
}

function isNative() {
    return Platform.OS === 'android' || Platform.OS === 'ios'
}

function isAppOrWindows() {
    const platform = getPlatform();

    return platform === 'android' || platform === 'ios' || platform === 'windows'
}

function getDocumentTitle() {
    const platform = getPlatform();

    switch (platform) {
        case 'windows':
            return 'GRAMS W10'
        case 'android':
            return 'GRAMS M10'
        case 'ios':
            return 'GRAMS M10'
        case 'web':
            return 'GRAMS Web Dashboard'
        default:
            return 'GRAMS'
    }
}

export function getEpochDate(epochTime) {
    if (epochTime === undefined) {
        return undefined
    } else {
        return new Date(epochTime * 1000).toLocaleString()
    }
}

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

function printEpochDateRange(tag, range) {
    if (range === undefined) {
        console.log(tag, range)
    } else {
        console.log(tag, getEpochDate(range['from']), getEpochDate(range['to']))
    }
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

// validate phone number with country code
const validatePhoneNumber = (input_str) => {
    return input_str.match(/^[+]?[0-9]{10,13}$/);
}

const formatNumberToDecimalPlaces = (number, decimalPlaces = 2) => {
    return parseFloat(number).toFixed(decimalPlaces)
}

const mimeTypeFromDataUri = (dataUri) => {
    if (!dataUri || dataUri.uri === undefined) {
        return null;
    }

    dataUri = dataUri.uri;

    return dataUri.substring(dataUri.indexOf(":") + 1, dataUri.indexOf(";"));
}

const getFileExtensionFromMimeType = (mimeType) => {
    if (!mimeType) {
        return "png";
    }

    if (mimeType === 'jpeg'
        || mimeType === 'jpg'
        || mimeType === 'image/jpeg'
        || mimeType === 'image/jpg') {
        return 'jpg';
    }

    if (mimeType === 'png'
        || mimeType === 'image/png') {
        return 'png';
    }

    return "png";
}

const getFileExtensionFromDataUri = (dataUri) => {
    return getFileExtensionFromMimeType(mimeTypeFromDataUri(dataUri));
}

const getFileExtensionFromFileName = (fileName) => {
    if (!fileName) {
        return "png";
    }

    if (fileName.endsWith('.jpeg')
        || fileName.endsWith('.jpg')
        || fileName.endsWith('.image/jpeg')
        || fileName.endsWith('.image/jpg')) {
        return 'jpg';
    }

    if (fileName.endsWith('.png')
        || fileName.endsWith('.image/png')) {
        return 'png';
    }

    return "png";
}

const getImageMimeTypeFromExtension = (extension) => {
    if (extension === 'jpg' || extension === 'jpeg') {
        return 'image/jpeg';
    }

    if (extension === 'png') {
        return 'image/png';
    }

    return 'image/png';
}

const camelCaseToWords = (s) => {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const isNullOrUndefinedOrEmpty = (value) => {
    return value === null || value === undefined || value === '';
}

const constructQueryParamsFromObject = (queryParams) => {
    if (!queryParams) {
        return '';
    }

    return Object.keys(queryParams)
        .filter(key => queryParams[key] !== undefined && queryParams[key] !== null)
        .map(key => {
            return key + '=' + queryParams[key]
        }).join('&');
}


const setLocaleInStorage = async (locale) => {
  try {
    await AsyncStorage.setItem('locale', locale);
  } catch (error) {
    console.error('Failed to save locale:', error);
  }
};

const getLocaleFromStorage = async () => {
  try {
    const locale = await AsyncStorage.getItem('locale');
    return locale || 'en'; 
  } catch (error) {
    console.error('Failed to load locale:', error);
    return 'en';
  }
};

const getPathFileName = path => path.split('/').pop();

const getGrainName = (grainTypes, grainId) => {
    const grain = grainTypes?.find(g => g.grainId === grainId);
    return grain ? `${grain.name} (${grainId})` : grainId;
  };

export {
    getAvatarInitials,
    uniqByKeepFirst,
    getUniqueJobs,
    isNumeric,
    isNative,
    isAppOrWindows,
    getDocumentTitle,
    toTitleCase,
    getUniqueItems,
    printEpochDateRange,
    validateEmail,
    validatePhoneNumber,
    formatNumberToDecimalPlaces,
    mimeTypeFromDataUri,
    getFileExtensionFromMimeType,
    getFileExtensionFromDataUri,
    getFileExtensionFromFileName,
    getImageMimeTypeFromExtension,
    camelCaseToWords,
    isNullOrUndefinedOrEmpty,
    constructQueryParamsFromObject,
    setLocaleInStorage,
    getLocaleFromStorage,
    getPathFileName,
    getGrainName,
}