export const stackHomeScreen = (stackName: string) => {
    if (stackName === 'appUser' || stackName === 'scAppUser') {
        return 'ChooseGrainProfile'
    } else if (stackName === 'orgAdmin') {
        return 'OrganizationJobsPage'
    } else if (stackName === 'orgSuperAdmin') {
        return 'OrganizationDashboardPage'
    } else if (stackName === 'appViewOnlyUser') {
        return 'UserJobsPage'
    }
    else if(stackName == 'mlAdmin') {
        return 'ChooseGrainTypePage'
    }
}

export const defaultStackForGroup = (group: string) => {
    if (group === 'appUser' || group === 'scAppUser') {
        return 'appUser'
    } else if (group === 'orgAdmin') {
        return 'orgAdmin'
    } else if (group === 'orgSuperAdmin') {
        return 'orgSuperAdmin'
    } else if (group === 'l10AppUser') {
        return 'appViewOnlyUser'
    } else if(group == 'mlAdmin') {
        return 'mlAdmin'
    }

    return "stackChooser"
}